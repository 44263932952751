import './App.css';
import { Route, Routes } from 'react-router-dom'
import Navbar from './components/navbar'
import Home from './pages'
import Games from './pages/games'
import Blog from './pages/blog';

function App() {
  return (
    <div>
      <div className='title'>
        <h1>Eruna.Me</h1>
      </div>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/games' element={<Games />} />
        <Route path='/blog' element={<Blog />} />
      </Routes>
    </div>
  );
}

export default App;
