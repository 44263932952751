import React from 'react';

const Games = () => {
    return (
        <div className='body'> 
            <p>Oh no we have no backend yet so you can't play SeihouOnline(tm), how sad.</p>
        </div>      
    )
}

export default Games;