import React from 'react';

const Home = () => {
    return (
        <div className='body'>
            <p>
                What a beautiful website 
                <br/>
                10/10 IGN
            </p>
        </div>
    )
}

export default Home;