import React from 'react';

const Blog = () => {
    return (
        <div className='body'> 
            <p>This is a test!!</p>
        </div>      
    )
}

export default Blog;